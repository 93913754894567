<template>
  <div>
    <div class="t-list">
      <div class="t-top">
        <div class="t-search">
          <!-- 考试名称 -->
          <div class="t-input">
            <el-select
                v-model="params.exam_id"
                :placeholder="'选择考试名称'"
                :popper-append-to-body="false"
                size="small"
                @change="examChange">
              <el-option
                  v-for="item in examOptions"
                  :label="item['exam_name']"
                  :value="item['exam_id']"
              ></el-option>
            </el-select>
          </div>

          <!-- 筛选学校 -->
          <div class="t-input">
            <el-select
                v-model="params.school_ids"
                :placeholder="'筛选学校'"
                :popper-append-to-body="false"
                size="small"
                @change="schoolChange($event,'school')">
              <template v-if="schoolOptions.length">
                <el-option label="全部选择" value="0"></el-option>
              </template>
              <el-option
                  v-for="item in schoolOptions"
                  :label="item['school_name']"
                  :value="item['school_id']"
              ></el-option>
            </el-select>
          </div>

          <!-- 筛选年级 -->
          <div class="t-input">
            <el-select
                v-model="params.grade_id"
                :placeholder="'筛选年级'"
                :popper-append-to-body="false"
                size="small"
                @change="schoolChange($event,'grade')">
              <el-option
                  v-for="item in gradeOptions"
                  :label="item['grade_name']"
                  :value="item['grade_id']"
              ></el-option>
            </el-select>
          </div>

          <!-- 筛选科目  v-model="subjectValue" -->
          <div class="t-input">
            <el-cascader
                v-model="params.subject_id"
                :options="subjectOptions"
                :placeholder="'筛选科目'"
                :popper-append-to-body="false"
                :props="{
                  expandTrigger: 'hover',
                  label: 'subject_name',
                  value: 'id',
                  emitPath: false,
                }"
                :key="cascaderKey"
                :show-all-levels='false'
                size="small"
                @change="selectChange"
            ></el-cascader>
          </div>

          <!-- 筛选教学班 -->
          <div class="t-input">
            <el-select
                v-model="params.teaching_ids"
                :placeholder="'筛选班级'"
                :popper-append-to-body="false"
                size="small"
                @change="selectChange">
              <template v-if="teachingOptions.length">
                <el-option label="全部选择" value="0"></el-option>
              </template>
              <el-option
                  v-for="item in teachingOptions"
                  :label="item['class_name']"
                  :value="item['id']"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: #f7f7f7;padding-top: 12rem">
      <div class="main-box">
        <div class="grade-type">
        <span
            v-for="item in checkType"
            :class="checkTypeId === item.id ? 'active' : ''"
            @click="typeCheck(item.id)"
        >{{ item.name }}</span>
        </div>

        <div class="main-content">
          <div class="main-content-header">
            <div class="main-content-header-left">{{ checkTypeId === 1 ? '题号' : '知识点' }}</div>
            <div class="main-content-header-right">
              <div>{{ checkTypeId === 1 ? '答题情况' : '知识点掌握情况' }}</div>
              <div class="main-content-header-right-little">
                <template v-if="checkTypeId === 1">
                  <span>题目得分率：{{ questionContentDesc.total_score_rate }}</span>
                  <span>方差：{{ questionContentDesc.variance }}</span>
                </template>
                <template v-if="checkTypeId === 2">
                  <span>知识点得分率：{{ knowledgeContentDesc.total_score_rate }}</span>
                  <span>方差：{{ knowledgeContentDesc.variance }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="main-content-body">

            <div v-show="checkTypeId === 1" class="main-content-body-left">
              <ul v-if="questionList.length" ref="questionList" @scroll="handleScroll_1">
                <li
                    v-for="item in questionList"
                    :class="questionId === item.serialnum ? 'active' : ''"
                    @click="questionCheck(item.serialnum)">
                  题目：{{ item.serialnum }}
                </li>
              </ul>
              <div v-else class="f14" style="margin-top: 110rem">暂无数据</div>
            </div>
            <div v-show="checkTypeId === 2" class="main-content-body-left">
              <ul v-if="knowledgeList.length" ref="knowledgeList" @scroll="handleScroll_2">
                <li
                    v-for="item in knowledgeList"
                    :class="knowledgeId === item.id ? 'active' : ''"
                    @click="knowledgeCheck(item.id)">
                  {{ item.name }}
                </li>
              </ul>
              <div v-else class="f14" style="margin-top: 110rem">暂无数据</div>
            </div>

            <div v-show="checkTypeId === 1" class="main-content-body-right">
              <div class="main-content-body-right-top">
                <p class="f-bold mr-20 ml-20 text-nowrap">知识点的内容</p>
                <i class="f14 c6">{{ questionContentDesc.ability_names }}</i>
              </div>
              <div class="main-content-body-right-middle">
                <div style="margin-top: 15rem;padding-left: 20rem">题目满分：{{ questionContentDesc.score }}</div>
                <div class="t-table" style="margin-top: 15rem">
                  <list-template
                      :current-page="questionTablePage"
                      :loading="questionTableLoading"
                      :page-size="pageSize"
                      :table-config="tableConfig1"
                      :table-data="questionTableData"
                      :total="questionTableTotal"
                      @onChangePage="changeQuestionPage"
                  ></list-template>
                </div>
              </div>
            </div>

            <div v-show="checkTypeId === 2" class="main-content-body-right">
              <div class="main-content-body-right-top">
                <p class="f-bold mr-20 ml-20 text-nowrap">题目编号 </p>
                <i class="f14 c6">
                  {{ knowledgeContentDesc.exam_serialnums }}
                </i>
              </div>
              <div class="main-content-body-right-middle">
                <div class="t-table" style="margin-top: 15rem">
                  <list-template
                      :current-page="knowledgeTablePage"
                      :loading="knowledgeTableLoading"
                      :page-size="pageSize"
                      :table-config="tableConfig2"
                      :table-data="knowledgeTableData"
                      :total="knowledgeTableTotal"
                      @onChangePage="changeKnowledgePage"
                  ></list-template>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "@/utils/tools";

export default {
	_config:{"route":{"path":"details","meta":{"title":"数据展示"}}},
  data() {
    return {
      // mock_url: 'https://mock.mengxuegu.com/mock/622089d4e7ee771c8c6e2148/hh',
      mock_url: '',
      isSelectFirst: false,

      //考试选择配置
      examOptions: [],

      //筛选学校配置
      schoolOptions: [],

      //筛选年级配置
      gradeOptions: [],

      //筛选科目配置
      subjectOptions: [],
      cascaderKey: 1,

      //筛选教学班配置
      teachingOptions: [],

      //首次请求后保存请求信息到这里，然后每次切换page的时候直接拿这里面的数据
      params: {
        exam_id: '',
        school_ids: '',
        grade_id: '',
        teaching_ids: '',
        subject_id: '',
        pageSize: '',
      },

      //首次获取的筛选配置，包含了除科目外所有的筛选条件
      dataCont: [],

      //题目 or 知识点
      checkType: [{name: '按题目', id: 1}, {name: '按知识点', id: 2}],
      checkTypeId: 1,

      //題目列表
      questionList: [],
      questionId: '',
      questionPage: 1,
      isQuestionLoaded: false,
      //知识点列表
      knowledgeList: [],
      knowledgeId: '',
      knowledgePage: 1,
      isKnowledgeLoaded: false,
      listPageSize: 20,

      //题目或者知识点的概述
      questionContentDesc: '',
      knowledgeContentDesc: '',

      tableConfig1: [
        {prop: "student_name", label: "学生姓名"},
        {prop: "stu_score", label: "实际得分"},
        {prop: "score_rate", label: "题目得分率"},
        {prop: "teaching_class_name", label: "教学班"},
      ],
      tableConfig2: [
        {prop: "student_name", label: "学生姓名"},
        {prop: "err_serialnums", label: "错误题目", render: (row) => row.err_serialnums ? row.err_serialnums : '无'},
        {prop: "score_rate", label: "知识点得分率"},
        {prop: "teaching_class_name", label: "教学班"},
      ],
      questionTableLoading: false,
      knowledgeTableLoading: false,
      questionTableData: [],
      knowledgeTableData: [],

      //当前页码
      questionTablePage: 1,
      knowledgeTablePage: 1,
      questionTableTotal: null,
      knowledgeTableTotal: null,
      pageSize: 10,

    };
  },
  created() {
    this.getSearchConfig()
  },
  mounted() {
    this.handleScroll_1 = debounce((e) => {
      if (!this.isQuestionLoaded) {
        let a = this.$refs.questionList.scrollHeight - this.$refs.questionList.scrollTop
        if (a < 690) {
          this.questionPage++
          this.getQuestionList()
        }
      }
    }, true, 500)

    this.handleScroll_2 = debounce((e) => {
      if (!this.isKnowledgeLoaded) {
        let a = this.$refs.knowledgeList.scrollHeight - this.$refs.knowledgeList.scrollTop
        if (a < 690) {
          this.knowledgePage++
          this.getKnowledgeList()
        }
      }
    }, true, 500)
  },
  methods: {
    //获取查询条件
    getSearchConfig() {
      this.$_axios.post(this.mock_url + '/site/get-query-cond').then(res => {
        let data = res.data.data;
        if (data.length === 0) return;
        this.dataCont = data;
        data.forEach(item => {
          this.examOptions.push(item)
        })
      })
    },

    //选择考试名称后触发事件,e为考试id
    examChange(e) {

      ++this.cascaderKey
      //清空数据
      this.params.school_ids = null
      this.params.grade_id = ''
      this.params.teaching_ids = null
      this.params.subject_id = ''
      this.schoolOptions = []
      this.gradeOptions = []
      this.subjectOptions = []
      this.teachingOptions = []
      this.resetData()

      //获取科目配置
      this.$_axios.get(this.mock_url + '/site/exam-subject', {params: {exam_id: e}}).then(res => {
        let data = res.data.data;
        this.subjectOptions = JSON.parse(JSON.stringify(data).replace(/child/g, 'children'))
      })

      let data = this.dataCont
      for (let i = 0; i < data.length; i++) {
        if (Number(e) === Number(data[i].exam_id)) {
          this.schoolOptions = data[i].school_data
          this.gradeOptions = data[i].grade_data
          return;
        }
      }
    },

    //选择学校事件
    schoolChange(e, str) {
      this.teachingOptions = []
      this.params.teaching_ids = null
      this.resetData()

      if (str === 'school') this.params.grade_id = '';
      let params = {
        school: this.params.school_ids,
        exam: Number(this.params.exam_id)
      }
      if (str === 'grade') params.grade = Number(this.params.grade_id)

      this.$_axios.get('/site/role-class', {params}).then(res => {
        if (res.data.data.length) {
          this.teachingOptions = res.data.data
        } else {
          this.teachingOptions = []
        }
      })
    },

    //科目、教学班选择后，如果必要条件都已筛选，则直接默认请求题号列表
    selectChange() {
      this.resetData()
      if (this.params.grade_id && this.params.subject_id && this.params.teaching_ids !== null) {
        this.getQuestionList(1)
        this.getKnowledgeList(1)
      }
    },

    //类型切换  按题目 or 知识点
    typeCheck(id) {
      if (!this.checkSelect(this.params)) return;
      this.checkTypeId = id;
    },

    //获取题目或知识点列表  type为1时，是上方的搜索，这时题号或知识点要默认第一个   type为2时，是题号或知识点列表的下拉加载，这时不需要设置默认题号
    getQuestionList(type) {
      //根据题目或知识点切换请求路径
      let url = '/exam-analysis/get-topic-list'
      let params = {
        ...this.params,
        pageSize: this.listPageSize,
        page: this.questionPage,
      }

      this.$_axios.post(url, {...params}).then(res => {
        let data = res.data.data
        if (data.length) {
          this.questionList = [...this.questionList, ...res.data.data]

          if (type === 1) {
            this.questionId = data[0].serialnum
            this.questionCheck(data[0].serialnum)
          }
          //取返回值中总页码，进行对比，判断是否还需要下拉加载
          this.isQuestionLoaded = Number(res.headers['x-pagination-page-count']) === this.questionTablePage
        }
      })
    },

    getKnowledgeList(type) {
      let url = '/exam-analysis/get-knowledge-list'
      let params = {
        ...this.params,
        pageSize: this.listPageSize,
        page: this.knowledgePage,
      }

      this.$_axios.post(url, {...params}).then(res => {
        let data = res.data.data
        if (data.length) {
          this.knowledgeList = [...this.knowledgeList, ...res.data.data]
          if (type === 1) {
            this.knowledgeId = data[0].id
            this.knowledgeCheck(data[0].id)
          }
          //取返回值中总页码，进行对比，判断是否还需要下拉加载
          this.isKnowledgeLoaded = Number(res.headers['x-pagination-page-count']) === this.knowledgeTablePage
        }
      })
    },

    //切换题目序号或者知识点序号
    questionCheck(id) {
      if (this.questionTableLoading) return;
      this.questionId = id
      this.questionTablePage = 1
      this.questionTableTotal = null

      let url = this.mock_url + '/exam-analysis/by-topic-profile',
          params = {...this.params}
      params.serialnum = id

      this.$_axios.post(url, {...params}).then(res => {
        this.questionContentDesc = res.data.data
      })
      this.onQuestionSearch(params)
    },

    knowledgeCheck(id) {
      if (this.knowledgeTableLoading) return;
      this.knowledgeId = id
      this.knowledgeTablePage = 1
      this.knowledgeTableTotal = null

      let url = this.mock_url + '/exam-analysis/by-knowledge-profile',
          params = {...this.params}
      params.ability_id = id

      this.$_axios.post(url, {...params}).then(res => {
        this.knowledgeContentDesc = res.data.data
      })
      this.onKnowledgeSearch(params)
    },

    //表格左边列表的下拉加载
    handleScroll_1() {
    },
    handleScroll_2() {
    },

    changeQuestionPage(e) {
      this.questionTablePage = e;
      this.onQuestionSearch(this.params)
    },

    changeKnowledgePage(e) {
      this.knowledgeTablePage = e;
      this.onKnowledgeSearch(this.params)
    },


    // 搜索功能
    onQuestionSearch(params) {
      this.questionTableLoading = true

      let url = this.mock_url + '/exam-analysis/by-topic'
      params.page = this.questionTablePage
      params.pageSize = this.pageSize
      params.serialnum = this.questionId

      this.$_axios.post(url, params).then(res => {
        this.questionTableLoading = false
        let data = res.data.data
        if (data.length) {
          let {total} = this.$tools.getPaginationInfo(res.headers);
          this.questionTableData = res.data.data
          this.questionTableTotal = total;
        } else {
          this.questionTableData = []
        }
      })
    },

    // 搜索功能
    onKnowledgeSearch(params) {
      this.knowledgeTableLoading = true

      let url = this.mock_url + '/exam-analysis/by-knowledge'
      params.page = this.knowledgeTablePage
      params.pageSize = this.pageSize
      params.ability_id = this.knowledgeId

      this.$_axios.post(url, params).then(res => {
        this.knowledgeTableLoading = false
        let data = res.data.data
        if (data.length) {
          let {total} = this.$tools.getPaginationInfo(res.headers);
          this.knowledgeTableData = res.data.data
          this.knowledgeTableTotal = total;
        } else {
          this.knowledgeTableData = []
        }
      })
    },

    //校验检索框有没有选中所有必选项
    checkSelect(params) {
      if (!params.exam_id) {
        this.$message({showClose: true, message: '请选择考试名称！', type: 'warning'});
        return false;
      }
      if (params.school_ids === null) {
        this.$message({showClose: true, message: '请筛选学校！', type: 'warning'});
        return false;
      }
      if (!params.grade_id) {
        this.$message({showClose: true, message: '请筛选年级！', type: 'warning'});
        return false;
      }
      if (!params.subject_id) {
        this.$message({showClose: true, message: '请筛选科目！', type: 'warning'});
        return false;
      }
      if (params.teaching_ids === null) {
        this.$message({showClose: true, message: '请筛选教学班！', type: 'warning'});
        return false;
      }
      return true
    },

    resetData() {
      this.checkTypeId = 1;

      this.questionPage = 1
      this.questionList = []
      this.knowledgePage = 1
      this.knowledgeList = []

      this.questionContentDesc = ''
      this.knowledgeContentDesc = ''

      this.questionTableData = []
      this.knowledgeTableData = []
      this.questionTablePage = 1
      this.knowledgeTablePage = 1
    }
  },
};
</script>

<style lang="scss" scoped>
.grade-type {
  display: flex;
}

.t-list {
  padding: 25rem 0 0 32rem;
  //height: 100%;
  .t-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40rem;

    .t-search {
      display: flex;

      .t-input {
        margin-bottom: 12rem;
        flex: 1;
        margin-right: 20rem;

        &:last-child {
          margin-right: 0;
        }

        .el-select {
          width: 100%;
        }
      }

    }
  }

  .t-handle {
    margin-right: 15rem;
  }

  .t-handle:last-child {
    margin-right: 0;
  }

  .t-pagination {
    margin-top: 50rem;
  }

}

.main-box {
  padding: 30rem;
  background-color: #fff;

  .grade-type {
    display: flex;
    flex-wrap: wrap;

    span {
      cursor: pointer;
      //border-right: 1rem solid #ccc;
      //border-bottom: 1rem solid #ccc;
      width: 200rem;
      height: 44rem;
      line-height: 44rem;
      color: #333;
      font-size: 14rem;
      text-align: center;
      transition: 0.2s;
      background-color: #fafafa;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        //border-top: 1rem solid #ccc;
      }

      &:nth-child(5n + 1) {
        //border-left: 1rem solid #ccc;
      }

      &.active {
        background-color: #1C4EFD;
        color: #fff;
      }
    }
  }

  .main-content {

    .main-content-header {
      display: flex;
      background-color: #E8EDFF;
      font-weight: bold;
      line-height: 44rem;
      margin-top: 20rem;

      .main-content-header-left {
        width: 200rem;
        text-align: center;
        border: 1rem solid #ededed;
      }

      .main-content-header-right {
        width: calc(100% - 200rem);
        display: flex;
        justify-content: space-between;
        border: 1rem solid #ededed;
        border-left-width: 0;
        padding-left: 40rem;

        .main-content-header-right-little {
          font-weight: normal;
          font-size: 14rem;

          span {
            margin-right: 40rem;
          }
        }
      }
    }

    .main-content-body {
      display: flex;

      .main-content-body-left {
        width: 200rem;
        border: 1rem solid #ededed;
        border-top-width: 0;
        display: flex;
        justify-content: center;

        ul {
          width: 100%;
          height: 639rem;
          padding: 0 20rem;
          margin: 10rem auto;
          overflow-y: auto;

          li {
            width: 140rem;
            border-radius: 6rem 6rem;
            transition: 0.2s;
            text-align: center;
            cursor: pointer;
            line-height: 24rem;
            margin: 14rem auto;
            padding: 4rem 10rem;
            font-size: 14rem;

            &.active,
            &:hover {
              background-color: #1C4EFD;
              color: #fff;
            }
          }
        }
      }

      .main-content-body-right {
        width: calc(100% - 200rem);
        border-right: 1rem solid #ededed;
        border-bottom: 1rem solid #ededed;
        padding: 12rem 20rem 20rem;
        height: 660rem;

        .main-content-body-right-top {
          background-color: #fafafa;
          //border: 1rem solid #ededed;
          display: flex;
          padding: 12rem 0;
        }
      }
    }
  }
}


::v-deep .el-select .el-select__tags > span {
  display: flex;
  overflow: hidden;
}

::v-deep .el-table th.rem {
  background-color: #E8EDFF !important;
}

::v-deep .el-table thead {
  color: #333;
}
</style>
